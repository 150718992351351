import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Container } from "react-bootstrap";

import "bootstrap/dist/css/bootstrap.min.css";
import "./TNavbar.css";

function TNavbar() {
  return (
    <div className="TNavContainer">
      <Navbar collapseOnSelect expand="lg" variant="light">
        <Container>
          <Navbar.Brand href="/">
            <img
              alt="TL"
              src="/favicon.ico"
              width="30"
              height="30"
              className="d-inline-block align-top"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav>
              <NavDropdown title="Resume" id="collasible-nav-dropdown">
                <NavDropdown.Item className="navbar-color-choice" href="/resume#work_experience">
                  Work Experience
                </NavDropdown.Item>
                <NavDropdown.Item className="navbar-color-choice" href="/resume#education">
                  Education
                </NavDropdown.Item>
                <NavDropdown.Item className="navbar-color-choice" href="/resume#volunteer_and_leadership">
                  Volunteer and Leadership
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown title="Portfolio" id="collasible-nav-dropdown">
                <NavDropdown.Item className="navbar-color-choice" href="/portfolio#campgrounds_project">
                  Campground Site Reservation Project
                </NavDropdown.Item>
                <NavDropdown.Item className="navbar-color-choice" href="/portfolio#secrets_project">
                  Secrets Authentication Project
                </NavDropdown.Item>
                <NavDropdown.Item className="navbar-color-choice" href="/portfolio#sign_up_project">
                  Shift Sign Up Project
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default TNavbar;
