import Footer from "../../components/Footer/Footer";
import TNavbar from "../../components/TNavbar/TNavbar";

import "./ResumePage.css";

import { useTranslation } from "react-i18next";

const ResumePage = () => {
  const { t, i18n } = useTranslation("translation", {
    keyPrefix: "resumePage",
  });
  const jobs = t("resumePage", { returnObjects: true });
  return (
    <div>
      <TNavbar />
      <div className="App">
        <div className="resume">
          <h1 className="resume-page-title">Resume</h1>
          <div className="resume-header-container">
            <h1 className="resume-name resume-left">Victoria Luce</h1>
            <div className="resume-contact-section resume-right">
              <p>Resume: toriluce.com </p>
              <p>Github: github.com/toriluce </p>
              <p>Contact: victorialuce34@gmail.com </p>
            </div>
          </div>
          <br />
          <div className="resume-body">
            <h1 className="resume-left" id="work_experience">
              WORK EXPERIENCE
            </h1>
            <div className="resume-item-container">
              <p className="resume-date resume-left">{t("barista.dates")}</p>
              <p className="resume-item resume-left">
                {t("barista.titleLocation")}
              </p>
            </div>
            <p className="resume-award resume-left">
              {t("barista.awards.award1")}
            </p>
            <p className="resume-award resume-left">
              {t("barista.awards.award2")}
            </p>
            <p className="resume-description resume-left">
              {t("barista.description")}
            </p>
            {/* ************** */}
            <div className="resume-item-container">
              <p className="resume-date resume-left">
                {t("leadTechnician.dates")}
              </p>
              <p className="resume-item resume-left">
                {t("leadTechnician.titleLocation")}
              </p>
            </div>
            <p className="resume-description resume-left">
              {t("leadTechnician.description")}
            </p>
            {/* ************** */}

            <div className="resume-item-container">
              <p className="resume-date resume-left">{t("pharmTech.dates")}</p>
              <p className="resume-item resume-left">
                {t("pharmTech.titleLocation")}
              </p>
            </div>
            <p className="resume-description resume-left">
              {t("pharmTech.description")}
            </p>
            <div className="resume-item-container">
              <p className="resume-date resume-left">
                {t("memberServices.dates")}
              </p>
              <p className="resume-item resume-left">
                {t("memberServices.titleLocation")}
              </p>
            </div>
            <p className="resume-description resume-left">
              {t("memberServices.description")}
            </p>
            <div className="resume-item-container">
              <p className="resume-date resume-lefte">
                {t("teamMember.dates")}
              </p>
              <p className="resume-item resume-left">
                {t("teamMember.titleLocation")}
              </p>
            </div>
            <p className="resume-description resume-left">
              {t("teamMember.description")}
            </p>
            <div className="resume-item-container">
              <p className="resume-date resume-lefte">
                {t("vetAssistant.dates")}
              </p>
              <p className="resume-item resume-left">
                {t("vetAssistant.titleLocation")}
              </p>
            </div>
            <p className="resume-description resume-left">
              {t("vetAssistant.description")}
            </p>
            <br />
            <h1 className="resume-left" id="education">
              EDUCATION
            </h1>
            <div className="resume-item-container">
              <p className="resume-date">05/2022—Current</p>
              <p className="resume-item">
                100 Days of Code: The Complete Python Pro Bootcamp for 2022,
                Udemy
              </p>
            </div>
            <p className="resume-description resume-left">
              25 Completed Video Hours
            </p>
            <div className="resume-item-container">
              <p className="resume-date">12/2021—05/2022</p>
              <p className="resume-item">
                The Complete 2022 Web Development Bootcamp, Udemy
              </p>
            </div>
            <p className="resume-description resume-left">
              65 Completed Video Hours
            </p>
            <div className="resume-item-container">
              <p className="resume-date">08/2015—Current</p>
              <p className="resume-item">
                Undergraduate Degree in Multidisciplinary Studies of Computer Science & Biology, Oklahoma
                State University
              </p>
            </div>
            <p className="resume-description resume-left">
              90 Completed Credit Hours
            </p>
            <br />
            <h1 className="resume-left" id="volunteer_and_leadership">
              VOLUNTEER AND LEADERSHIP
            </h1>
            <div className="resume-item-container">
              <p className="resume-date">08/2015—08/2019</p>
              <p className="resume-item">Oklahoma State University The Rowdy</p>
            </div>
            <p className="resume-item-info resume-left">
              Vice President ’18-‘19{" "}
            </p>
            <p className="resume-description resume-left">
              Coordinated with the OSU Athletic department on the organization
              of and idea development for student-led athletic spirit groups
            </p>
            <div className="resume-item-container">
              <p className="resume-date resume-left">08/2015—08/2019</p>
              <p className="resume-item resume-left">
                Oklahoma State University Paddle People
              </p>
            </div>
            <p className="resume-item-info resume-left">
              Camping Director ’18-‘19{" "}
            </p>
            <p className="resume-description resume-left">
              Worked within an executive committee to make decisions for the
              largest non-Greek, student-run organization at OSU. Developed and
              ran an interactive camping assignment page for members through
              excel. Managed members to maintain safety and tempo for a section
              of the stadium wall
            </p>
            <div className="resume-item-container">
              <p className="resume-date resume-left">01/2017—09/2018</p>
              <p className="resume-item resume-left">
                Center of Veterinary Health Sciences Operation Catnip
              </p>
            </div>
            <p className="resume-description resume-left">
              Assisted CVHS doctoral students in a clinic which spays, neuters,
              and vaccinates to treat 100+ captured-to-be-released stray cats
              around the community each month by preparing vaccines, sterilizing
              equipment, and maintaining records
            </p>
          </div>
          <a className="resume-portfolio-link" href="/portfolio">
            <h1 className="resume-left">➤ PORTFOLIO</h1>
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ResumePage;
