import { DateTime } from "luxon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";

import "./Footer.css";

function Footer() {
  const now = DateTime.now().year;

  return (
    <footer>
      <a
        href="/"
      >
        <p className="footer-text">Tori Luce {now}</p>
      </a>
      <div>
        <a
          href="https://linkedin.com/in/toriluce"
          rel="noreferrer noopener"
          target="_blank"
        >
          <FontAwesomeIcon className="footer-contact-icon" icon={faLinkedin} />
        </a>
        <a
          href="https://github.com/toriluce"
          rel="noreferrer noopener"
          target="_blank"
        >
          <FontAwesomeIcon className="footer-contact-icon" icon={faGithub} />
        </a>
        <a
          href="mailto:victorialuce34@gmail.com"
          rel="noreferrer noopener"
          target="_blank"
        >
          <FontAwesomeIcon className="footer-contact-icon" icon={faEnvelope} />
        </a>
      </div>
    </footer>
  );
}

export default Footer;
