import Footer from "../../components/Footer/Footer";
import TNavbar from "../../components/TNavbar/TNavbar";

import "./HomePage.css";

const HomePage = () => {
  return (
    <div>
      <TNavbar />
      <div className="App">
        <div className="homepage">
          <h1 className="homepage-name">Tori Luce</h1>
          <p className="homepage-description">Web Developer</p>
          <div className="homepage-links">
            <a href="/resume">
              <button className="button">View My Resume</button>
            </a>
            <a href="/portfolio">
              <button className="button">View My Portfolio</button>
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HomePage;
