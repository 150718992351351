import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    en: {
        translation: {
            resumePage: {
                shiftSupervisor: {
                    dates: "11/2023—Current",
                    description: `Lead and encouraged`,
                    titleLocation: "Shift Supervisor, Starbucks – Atlanta, Georgia",
                },
                barista: {
                    awards: { 
                        award1: "Partner of the Quarter, Quarter Two ‘23", 
                        award2: "Barista Trainer" },
                    dates: "05/2023—12/2023",
                    description: `Maintained a clean and healthy working environment as well as 
                    company recipe and workflow standards, created positive personal connections with cafe guests, 
                    and nurtured self development and the development of other baristas through 
                    an ever-present curiosity for growth and excellence`,
                    titleLocation: "Barista, Starbucks – Atlanta, Georgia",
                },
                leadTechnician: {
                    dates: "10/2020—12/2021",
                    description: `Responded to customer/technician issues and 
                    provided resolutions to maintain customer satisfaction, maintained 
                    drug inventory, pharmacy documentation, and good relations with 
                    auditors, scheduled technician shifts, worked alongside the 
                    Pharmacy Manager to map daily workflow for technicians and 
                    staff pharmacists, and taught, led, and motivated a team of technicians`,
                    titleLocation: "Lead Pharmacy Technician, CVS Health – Broken Arrow, OK and Oak Point, TX",
                },
                pharmTech: {
                    dates: "03/2020—10/2020",
                    description: `Coordinated with pharmacists, doctors, insurance agents, patients,
                    and a team of technicians in person and over the phone to prepare
                    and deliver prescription drugs, verbally instructed and calmed
                    patients for self-swab COVID-19 testing, and worked with EPIC and
                    MyChart systems to test samples and submit medical insurance
                    claims`,
                    titleLocation: "Pharmacy Technician, CVS Health – Broken Arrow, OK"
                },
                memberServices: {
                    dates: "04/2021—06/2021",
                    description: `Established and maintained relationships with members, managed
                    member accounts and payments, promoted membership sales, and
                    maintained an exceptionally clean, energetic environment`,
                    titleLocation: "Member Services Associate, Life Time Fitness – Tulsa, OK",
                },
                teamMember: {
                    dates: "08/2017—01/2019",
                    description: `Developed strong relationships with guests and improved
                    operational excellence through problem-solving and working closely
                    with a team`,
                    titleLocation: "Front of House Team Member, Chick-fil-A – Stillwater, OK",
                },
                vetAssistant: {
                    dates: "08/2014—06/2015",
                    description: `Assisted veterinarians and technicians by filling prescriptions, 
                    performing blood and fecal collection and testing, prepping for surgery, 
                    preparing and administering vaccines, greeting clients, and maintaining clinic 
                    environment`,
                    titleLocation: "Veterinary Technician Assistant, Animal Hospital of Frisco – Frisco, TX ",
                },
            }
        }
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;