import Footer from "../../components/Footer/Footer";
import TNavbar from "../../components/TNavbar/TNavbar";

import "./PortfolioPage.css";

const PortfolioPage = () => {
  return (
    <div>
      <TNavbar />
      <div className="App">
        <div className="portfolio">
          <h1 className="portfolio-page-title">Portfolio</h1>
          <h1 className="portfolio-left">TYPESCRIPT</h1>
          <div className="portfolio-item-container">
            <p className="portfolio-date portfolio-left">2022</p>

            <p className="portfolio-item portfolio-left">
              Campsite Booking Service : Typescript, React, Node JS, Express,
              DynamoDB, AWS S3, AWS Route 53
            </p>
          </div>

          <p className="portfolio-description portfolio-left">
            This personal project uses React with Node JS and Express to allow
            users to browse 3 Campgrounds’ customized data stored in DynamoDB,
            see which campsites are available on selected days, enter their RV
            and contact information, and add a completed booking into a
            database
          </p>
          <a
            href="https://d27sf4qn87q1fm.cloudfront.net"
            rel="noreferrer noopener"
            target="_blank"
          >
            <button className="live-button">View Live</button>
          </a>
          <a
            href="https://github.com/toriluce/RVFrontend"
            rel="noreferrer noopener"
            target="_blank"
          >
            <button className="live-button">View Frontend Code</button>
          </a>
          <a
            href="https://github.com/toriluce/RVBackend"
            rel="noreferrer noopener"
            target="_blank"
          >
            <button className="live-button">View Backend Code</button>
          </a>
          <h1 className="portfolio-left">JAVASCRIPT</h1>
          <div id="camgrounds_project" className="portfolio-item-container">
            <p className="portfolio-date portfolio-left">2022</p>
            <p className="portfolio-item portfolio-left">
              Secrets Authentication Project : Javascript, Express, EJS, Node
              JS, Passport, Google OAuth 2.0
            </p>
          </div>

          <p className="portfolio-description portfolio-left">
            This was the final authentication project built in coding bootcamp.
            It allows the user to sign in and post an anonymous message to
            become publicly visible. Accounts can be created by signing in with
            google or with an email and password. Passwords are saved in a
            database, hashed, and multi-round salted with bcrypt, and sessions
            are sustained with passport.js
          </p>
          <a
            href="https://github.com/toriluce/secrets-auth"
            rel="noreferrer noopener"
            target="_blank"
          >
            <button className="live-button">View Code</button>
          </a>
          <h1 className="portfolio-left">EXCEL</h1>

          <div id="sign_up_project" className="portfolio-item-container">
            <p className="portfolio-date portfolio-left">2018</p>
            <p className="portfolio-item portfolio-left">
              Camp Sign Up and Point Calculator : Excel
            </p>
          </div>

          <p className="portfolio-description portfolio-left">
            This excel project built for Oklahoma State University’s Paddle
            People uses lots of conditional formatting, protected ranges, basic
            functions, and drop-down lists to create an environment that allows
            members to see which hours need more sign-ups, sign up for shifts
            from home, and track earned points
          </p>
          <a
            href="https://docs.google.com/spreadsheets/d/15tWqFs1A88QIY_X9yUMabi7fS4676m0fczXR0XlTnjM/edit?usp=sharing"
            rel="noreferrer noopener"
            target="_blank"
          >
            <button className="live-button">View Live</button>
          </a>
          <a className="resume-link" href="/resume">
            <h1 className="portfolio-left">➤ RESUME</h1>
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PortfolioPage;
